import Hero from 'components/Home-Elements/Hero/Hero'
import React, { Component } from 'react'

export default class Home extends Component {
  render() {
    return (
      <div>
        <Hero/>
      </div>
    )
  }
}
